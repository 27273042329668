.overlay {
  background-color: #00000065;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.5s;
  z-index: 100;
}

.overlay-active {
  opacity: 1;
}

.sidebar-wrapper {
  background-color: #fff;
  height: 100vh;
  width: 250px;
  position: fixed;
  right: 0;
  top: 0;
  padding: 0.5em;
  transition: 0.5s;
  z-index: 100;
}

.hidden {
  transform: translateX(100%);
}

.active {
  transform: translateX(0);
}

ul.sidebar-list {
  padding-left: 0;
  list-style: none;
  width: 250px;
  z-index: 1;
}

.sidebar-link {
  transform: translateX(0);
  transition: 0.25s;
}

.sidebar-link:hover {
  transform: translateX(5px);
}

.sidebar-link a {
  color: #212529;
  text-decoration: none;
}

.sidebar-transparent .sidebar-link a {
  color: #ffffff;
}

.sidebar-link a:hover {
  color: #c4c4c4;
}

.sidebar-transparent .sidebar-link a:hover {
  color: #cfcfcf;
}

.active-link a {
  color: #ad2222;
}

.sidebar-transparent .active-link a {
  color: #fff;
}

.close-btn {
  width: 36px;
  box-shadow: none;
  border: none;
  outline: none !important;
  background-color: transparent;
  padding: 8px;
}

.close-btn img {
  width: 100%;
}
