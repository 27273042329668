html {
  overflow-x: hidden;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Montserrat", -apple-system, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-scroll {
  overflow: hidden;
}
