
.header-h1 {
  font-weight: 400;
  font-size: 1.2em;
  margin: 0;
}

.header-h1 span {
  color: #8E8E8E;
}

.name {
  color: #8E8E8E;
}

#header-nav {
  background-color: #fff;
}

#header-nav.header-home {
  background-color: transparent;
  z-index: 20;
}

#header-nav h6 {
  cursor: pointer;
}

.menu-btn {
  width: 36px;
  box-shadow: none;
  border: none;
  outline: none !important;
  background-color: transparent;
  padding: 5px;
}

.menu-btn img {
  width: 100%;
}