.link {
    text-decoration: none;
}

.social-icon {
    fill: #212529;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
    transform: translateX(0);
    transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.25s;
}

.social-icon:hover {
    opacity: 0.7;
    transform: translateX(1px);
}