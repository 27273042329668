.news-card:hover {
  cursor: pointer;
}

.lazy-img {
  object-fit: cover;
  transition: all 0.3s !important;
}

.news-card:hover .lazy-img {
  transform: scale(1.025);
}

.modal-img {
  max-height: 250px;
  object-fit: cover;
}

.close-btn {
  z-index: 1000;
  background: none !important;
  width: 15px;
  right: 1em;
  top: 0.25em;
}

.news-highlight {
  min-height: 20rem;
}

.lazy-img {
  max-height: 400px;
}

@media (min-width: 768px) {
  .lazy-img {
    max-height: 200px;
  }
}
