.lazy-img {
  object-fit: cover;
  max-width: 300px;
}

.external-link {
  font-size: small;
  position: relative;
  top: -5px;
}

.project-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.project-label {
  background-color: #c4c4c4;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  padding: 3px 15px;
  border-radius: 0px 5px 5px 0px;
  transition: 0.15s;
  width: calc(100% - 15px);
}

.project-label.selected {
  background-color: #212529;
  width: 100%;
  transition: 0.15s;
}

.project-img-top {
  max-height: 150px;
  object-fit: cover;
  object-position: top;
}
