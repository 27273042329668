:root {
  --jcadb-red: #AD2222;
  --jcadb-dark-gray: #212529;
  --jcadb-light-gray: #c4c4c4;
  --jcadb-light-bg: #F5F5F5;
}

.App {
  color: var(--jcadb-dark-gray);
  font-size: .85em;
  background-color: var(--jcadb-light-bg);
  overflow: inherit;
  min-height: 100vh;
}

.section-divider {
  background-color: var(--jcadb-light-gray);
}

.section-title {
  color: var(--jcadb-red);
}

.text-red {
  color: var(--jcadb-red);
}

.page-container {
  min-height: calc(100vh - 52px);
}

.soundcloud-player {
  height: 100%;
}

/* Loader */

.loader {
  /* position: absolute;
  top: 50%;
  left: 48%; */
  margin-bottom: 16px;
  display: block;
  transition: 0.25s;
}

.line {
  background: #faf4ff;
  animation: pulse 1s ease-in-out infinite;
  display: inline-block;
  margin: 0 3px;
  width: 1px;
  height: 6px;
  border-radius: 10px;
}

.line:nth-child(2) {
  animation-delay: 180ms;
}

.line:nth-child(3) {
  animation-delay: 360ms;
}

.line:nth-child(4) {
  animation-delay: 540ms;
}

.line:nth-child(5) {
  animation-delay: 720ms;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
}