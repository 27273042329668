.home-container {
  background-color: #ad2222;
  color: #fff;
}

.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-wrap: nowrap;
  z-index: 2;
}

.title-400 {
  /* font-family: 'Lora', serif; */
  font-weight: 400;
  z-index: 2;
}

.title-700 {
  /* font-family: 'Lora', serif; */
  font-weight: 700;
}

hr {
  width: 80%;
  max-width: 350px;
}

.home-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: calc(45%) top;
  /* transition: cubic-bezier(0.215, 0.610, 0.355, 1) 1.5s; */
}

.home-img-author {
  z-index: 1;
}

.overlay {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.45)
  );
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.social-icon {
  fill: #fff;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
  transform: translateX(0);
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
}

.social-icon:hover {
  opacity: 0.7;
  transform: translateX(2px);
}

.copyright {
  bottom: 15px;
}

.events {
  opacity: 0;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.fade-in {
  opacity: 1;
}

@media (min-width: 768px) {
  .title-400 {
    font-size: 2.5rem;
  }

  .home-img {
    object-position: center top;
  }
}

@media (min-width: 1200px) {
  .title-400 {
    font-size: 2.75rem;
  }
}
