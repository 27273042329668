.bio-pic {
  max-height: 500px;
  object-fit: contain;
  object-position: top right;
}

.gallery-item {
  margin: 0;
  cursor: pointer;
}
