// $enable-grid-classes: false;
$enable-cssgrid: true;

// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// Default variable overrides
$primary: #ad2222;

$theme-colors: (
  "primary": $primary,
);

@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/bootstrap";

.btn-primary {
  background-color: $primary;
}
