.play-button,
.info-button {
  border: 0;
  padding: 0;
  background-color: transparent;
  outline: 0;
}

.info-button {
  position: absolute;
  top: 0.5rem;
  right: -0.85rem;
  rotate: -90deg;
}

.info-button span {
  font-weight: 500;
  opacity: 1;
  transition: 0.2s;
  color: var(--jcadb-red);
}

.info-button span.visible {
  opacity: 0;
  visibility: hidden;
}

.play-button img,
.info-button img {
  width: 24px;
  margin-right: 10px;
}

.card-header {
  height: 40px;
}

.card-header-button {
  fill: #ad2222;
  transform: rotate(0);
  transition: 0.15s;
}

.card-header-button.visible {
  transform: rotate(45deg);
}

.work-card {
  animation: fade 2s;
  -moz-animation: fade 2s; /* Firefox */
  -webkit-animation: fade 2s; /* Safari and Chrome */
  -o-animation: fade 2s; /* Opera */
}

.social-icon {
  fill: #ad2222;
  width: 24px;
  height: 24px;
  margin-right: 15px;
  cursor: pointer;
  transform: translateX(0);
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
}

.overlay-card {
  background-color: RGBA(255, 255, 255, 0.9);
  height: 0;
  overflow-y: hidden;
  transition: opacity 0.15s, height 0.25s;
  opacity: 0;
  z-index: 1;
}

.overlay-card.visible {
  /* visibility: visible; */
  /* overflow-y: overlay; */
  height: 100%;
  opacity: 1;
}

.overlay-card.visible.overflowed {
  overflow-y: overlay;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fade {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
