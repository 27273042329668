.Player {
    padding: 8px;
    /* max-height: 100vh;
    height: 100px; */
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    position: relative;
    /* background: aliceblue; */
    background-color: #333333de;
    color: #fff;
    position: fixed;
    z-index: 21;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.5s;
}

.Player.closed {
    bottom: -100%;
}

.Player.expanded {
    padding-bottom: 12px;
    /* background-color: blue; */
}

.Player .ctr-1 {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
}

.Player .ctr-1 .play-pause-text {
    font-size: 0.75rem;
    letter-spacing: 2px;
    padding-left: 2px;
}

.Player .ctr-1 .play-pause-text strong {
    font-weight: normal;
    margin-right: 15px;
}

.Player .ctr-1 .play-pause-text span {
    font-weight: bold;
    margin-right: 15px;
}

.Player .ctr-1 button {
    width: 35px;
    height: 35px;
    display: block;
    background: transparent;
    outline: none;
    border: none;
    padding: 5px;
    padding-right: 0;
    padding-left: 10px;
}

.Player .ctr-1 button svg {
    fill: tomato;
}

.Player .track-title {
    /* font-size: 2.6rem; */
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 2ch;
    /* margin-top: 0.5ch; */
    max-height: 9ch;
    width: 80%;
    overflow: auto;
    /* animation */
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
}

.Player .track-title .animated-track-title {
    display: inline-block;
    padding-left: 100%;
    text-indent: 0;
    -webkit-animation: marquee 15s linear infinite;
    animation: marquee 15s linear infinite;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.Player .bottom-ctr {
    position: absolute;
    bottom: 24px;
    left: 28px;
    right: 28px;
}

.Player .progress-container {
    position: relative;
}

.Player .progress-container .progress-bar {
    width: 100%;
    height: 2px;
    border-radius: 100000px;
    background: transparent;
    overflow: hidden;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.Player .progress-container .progress-bar .done {
    background: #fff;
    border-radius: 100000px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    z-index: 11;
}

.Player .progress-container .progress-bar .remaining {
    background: #fff;
    opacity: 0.2;
    border-radius: 100000px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.Player .progress-container .handle-ctr {
    position: absolute;
    width: 0px;
    overflow: visible;
    transform: translateY(-40%);
    left: 0;
}

.Player .progress-container .handle-ctr .time-span {
    display: inline-block;
    font-size: 0.75rem;
    letter-spacing: 2px;
    font-weight: bold;
    text-align: center;
    background: #ffffff20;
    color: #fff;
    margin-left: 50%;
    text-align: center;
    width: auto;
    max-width: 30vw;
    padding: 4px 4px 4px 5px;
    transform: translate(-50%, -50%);
    margin-bottom: 0px;
    position: relative;
}

.Player .progress-container .handle-ctr .time-span span {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    width: 6px;
    height: 6px;
    background: #333;
}

.Player .progress-container .handle-ctr .handle {
    display: block;
    width: 25px;
    height: 25px;
    background: transparent;
    outline: none;
    border: none;
    margin-left: 50%;
    transform: translate(-50%, -24%);
}

.Player .progress-container .handle-ctr .handle.active {
    display: block;
    width: 25px;
    height: 25px;
    background: transparent;
    outline: none;
    border: none;
    margin-left: 50%;
    transform: translate(-50%, -86%);
}

.Player .progress-container .handle-ctr .handle svg {
    fill: #fff;
}

.Player .control-icons-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
}

.Player .control-icons-container button.play-control {
    display: block;
    outline: none;
    border: none;
    width: 30px;
    height: 30px;
    /* background: aliceblue; */
    background-color: transparent;
    border-radius: 100000px;
    /* padding: 12px; */
}

.Player .control-icons-container button.play-control svg {
    fill: #fff;
}

.Player .volume-container {
    margin-bottom: 28px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
}

.Player .volume-container .volume-icon-ctr {
    min-width: 40px;
}

.Player .volume-container .volume-icon-ctr button {
    display: block;
    width: 22px;
    background: transparent;
    outline: none;
    border: none;
}

.Player .volume-container .volume-icon-ctr button svg {
    fill: #333;
}

.Player .volume-container .volume-pctg-ctr {
    min-width: 40px;
}

.Player .volume-container .volume-pctg-ctr span {
    display: block;
    text-align: right;
    font-weight: bold;
    letter-spacing: 0px;
    font-size: 1.4rem;
    padding-bottom: 4px;
}

.Player .volume-container .volume-bar-ctr {
    width: calc(100% - 80px);
    height: 3px;
    border-radius: 100000px;
    background: transparent;
    position: relative;
    transform: translateY(-55%);
}

.Player .volume-container .volume-bar-ctr .done {
    background: #333;
    border-radius: 100000px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    z-index: 11;
}

.Player .volume-container .volume-bar-ctr .remaining {
    background: #333;
    opacity: 0.2;
    border-radius: 100000px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.Player .volume-container .volume-bar-ctr .handle-ctr {
    position: absolute;
    width: 0px;
    transform: translateY(-41%);
    left: 0px;
    z-index: 12;
}

.Player .volume-container .volume-bar-ctr .handle {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100000px;
    padding: 4px;
    box-sizing: content-box;
    /* background: aliceblue; */
    background-color: #ad2222;
    outline: none;
    border: none;
    border: 3px solid #333;
    margin-left: 50%;
    transform: translateX(-50%);
}

.Player .bottom-icons-ctr {
    display: flex;
    flex-flow: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.Player .bottom-icons-ctr button {
    width: 28px;
    background: transparent;
    outline: none;
    border: none;
    text-align: center;
}

.Player .tracks-list-btn {
    width: 28px;
    background: transparent;
    outline: none;
    border: none;
    text-align: center;
}

.Player .bottom-icons-ctr button svg {
    fill: #fff;
    opacity: 0.6;
}

.Player .bottom-icons-ctr button.active svg {
    fill: #fff;
    opacity: 1;
}

.close-player-icon {
    width: 20px;
    box-shadow: none;
    border: none;
    outline: none !important;
    background-color: transparent;
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0.5em;
}
      
.close-player-icon img {
    width: 100%;
}

.work-playing-icon {
    width: max-content;
}

/* Audio animation */
.audio-animation {
    width: 28px;
    height: 32px;
    padding: 4px;
    padding-left: 0;
}

.audio-animation span {
    width: 2px;
    height: 24px;
    background: #ad2222;
    border-radius: 8px;
}

.audio-animation span:first-of-type {
  margin-top: 0;
}

.audio-animation span:nth-child(1) {
  animation: animationTest 1.2s infinite ease-in-out;
}

.audio-animation span:nth-child(2) {
  animation: animationTest 1.6s infinite ease-in-out;
}

.audio-animation span:nth-child(3) {
  animation: animationTest 1.4s infinite ease-in-out;
}

.audio-animation span:nth-child(4) {
  animation: animationTest 1s infinite ease-in-out;
}

@keyframes animationTest {
    0%   { height: 2px; }
    50% { height: 16px; }
    100% { height: 2px; }
}